.login-wrapper {
  width: 100%;
  height: 100%;
  display: table;
}

.login {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  background-image: url('../images/login-bg.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.input-text input {
  width: 300px;
  padding-left: 10px;
}

.text-white {
  color: #fff !important;
}

.input-border .MuiOutlinedInput-root fieldset,
.input-border .MuiOutlinedInput-root:hover fieldset {
  border-color: #ccc;
  border-width: 1px;
}

.input-border .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #ccc;
  border-width: 2px;
}

.loginTitle {
  margin: auto 0;
  color: #fff;
}

.loginTitle .logo {
  font-size: 30px;
}

.loginTitle .powered {
  font-size: 22px;
  margin-top: -10px;
  margin-bottom: 15px;
}

.login .btn-info {
  margin-top: 10px;
  background-color: #3f51b5 !important;
  background-color: #9E9E9E !important;
}

.login .btn-info:focus,
.login .btn-info:hover,
.login .btn-info:active {
  background-color: #303f9f !important;
  background-color: #424242 !important;
}

.login .alert .MuiAlert-message {
  width: 300px;
}

.login input.MuiInputBase-input,
.login input:-webkit-autofill,
.login input:-webkit-autofill:hover, 
.login input:-webkit-autofill:focus {
  -webkit-text-fill-color: #333;
  caret-color: #333;
  color: #333;
}
