html,
body,
#app {
  width: 100%;
  height: 100%;
  font-family: Helvetica;
  font-size: 13px;
}

body {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

a {
  background-color: transparent;
  text-decoration: none;
}

a:focus,
a:active,
a:hover {
  outline: 0;
  border: 0;
}

/* Form Filter */
.toolbar-wrapper {
  width: 100%;
  position: relative;
}

.toolbar-wrapper .MuiToolbar-regular {
  padding: 15px 0 !important;
}

.list-header {
  min-height: 36px;
  width: 100%;
}

.list-header .wrapper-form {
  position: absolute;
  left: 0;
}

.list-header .accordion-form {
  position: relative;
  padding-bottom: 15px;
}

.list-header .float-right {
  float: right;
  margin-top: 1px;
}

.list-header .MuiFormControl-marginDense {
  margin: 0 !important;
}

.list-header .MuiFormHelperText-root {
  display: none;
}

.list-header .MuiButton-text {
  padding: 7px 8px !important;
}

.list-header button {
  margin-left: 10px;
}

.list-header a .MuiButton-containedPrimary {
  padding: 7px 12px;
}

/* Form Input */
.form-control input,
.form-control textarea,
.form-control select {
  display: block;
  width: 100%;
  height: 30px;
  padding: 3px 12px;
  font-size: 13px;
  line-height: 1.6;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control input:focus,
.form-control textarea:focus,
.form-control select:focus,
.form-control .MuiSelect-selectMenu {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  color: #555;
}

.form-control .MuiSelect-select.Mui-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.form-control input::-moz-placeholder .form-control textarea::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control
  input:-ms-input-placeholder
  .form-control
  textarea:-ms-input-placeholder {
  color: #999;
}

.form-control input::-webkit-input-placeholder,
.form-control textarea::-webkit-input-placeholder {
  color: #999;
}

.form-control input::-ms-expand .form-control textarea::-ms-expand,
.form-control select::-ms-expand {
  border: 0;
  background-color: transparent;
}

.form-control input[disabled],
.form-control input[readonly],
.form-control textarea[disabled],
.form-control textarea[readonly],
.form-control select[disabled],
.form-control select[readonly] {
  background-color: #eeeeee;
  opacity: 1;
}

.form-control input[disabled],
.form-control textarea[disabled],
.form-control select[disabled] {
  cursor: not-allowed;
}

.search-area {
  padding-bottom: 10px;
}

.input-time .MuiFormControl-marginDense {
  width: auto !important;
}

.input-time input[type='time'] {
  width: 100px;
}

.input-time span {
  display: inline-block;
  padding: 0 10px 0 10px;
  margin-top: 10px;
}

.form-control textarea {
  height: auto !important;
}

.form-control .MuiFilledInput-multiline.MuiFilledInput-marginDense {
  padding: 0 !important;
}

/* Button */
.btn-primary,
.MuiButton-containedPrimary,
.MuiFab-primary {
  color: #fff;
  /* background-color: #38a4d7 !important;
  border-color: #0561a7 !important; */
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  height: 32px;
}

.btn-primary:focus,
.MuiButton-containedPrimary:focus,
.MuiFab-primary:focus {
  color: #fff;
  /* background-color: #2579a9 !important;
  border-color: #133d55 !important; */
  box-shadow: 1px 4px 3px rgba(0, 0, 0, 0.3);
}

.btn-primary:hover,
.btn-primary:active,
.MuiButton-containedPrimary:hover,
.MuiButton-containedPrimary:active,
.MuiFab-primary:hover,
.MuiFab-primary:active {
  color: #fff;
  /* background-color: #2579a9 !important;
  border-color: #1f648b !important; */
  box-shadow: 1px 4px 3px rgba(0, 0, 0, 0.3);
}

.btn-default {
  color: #333 !important;
  border: solid 1px #73737354 !important;
  background-color: #f9f9f9 !important;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
}

.btn-default:focus {
  color: #333;
  background-color: #e6e6e6 !important;
  border-color: #8c8c8c !important;
  box-shadow: 1px 4px 3px rgba(0, 0, 0, 0.3) !important;
}

.btn-default:hover,
.btn-default:active {
  color: #333;
  background-color: #e6e6e6 !important;
  border-color: #adadad !important;
  box-shadow: 1px 4px 3px rgba(0, 0, 0, 0.3) !important;
}

.btn-danger {
  color: #fff;
  background-color: #d73838;
  border: solid 1px #a70505;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
}

.btn-danger:focus {
  color: #fff;
  background-color: #954120;
  border-color: #411c0e;
  box-shadow: 1px 4px 3px rgba(0, 0, 0, 0.3);
}

.btn-danger:hover,
.btn-danger:active {
  color: #fff;
  background-color: #954120;
  border-color: #78341a;
  box-shadow: 1px 4px 3px rgba(0, 0, 0, 0.3);
}

.icon-default {
  color: #38a4d7 !important;
}

.icon-delete {
  color: #f44336 !important;
}

/* Input Image Area */
.img-input > div > div:first-child {
  border-radius: 4px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
}

.img-input .MuiFormHelperText-marginDense {
  margin-top: 2px;
}

.img-input img {
  margin: 0;
}

/* Dialogue */
.MuiDialog-paper {
  top: 0;
  position: fixed !important;
}

.MuiDialogTitle-root,
.MuiDialogContent-root {
  padding: 15px 24px !important;
  border-bottom: 1px solid #e9ecef;
}

.MuiDialogTitle-root h2,
.MuiDialogContent-root .MuiDialogContentText-root {
  text-align: left;
}

.MuiDialogActions-root button:first-child {
  color: #333;
  border: solid 1px #73737354;
  background-color: #f9f9f9;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
}

.MuiDialogActions-root button:first-child:focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
  box-shadow: 1px 4px 3px rgba(0, 0, 0, 0.3);
}

.MuiDialogActions-root button:first-child:hover,
.MuiDialogActions-root button:first-child:active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
  box-shadow: 1px 4px 3px rgba(0, 0, 0, 0.3);
}

.MuiDialogActions-root button:last-child {
  color: #fff;
  background-color: #d73838;
  border: solid 1px #a7050559;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
}

.MuiDialogActions-root button:last-child:focus {
  color: #fff;
  background-color: #954120;
  border-color: #411c0e;
  box-shadow: 1px 4px 3px rgba(0, 0, 0, 0.3);
}

.MuiDialogActions-root button:last-child:hover,
.MuiDialogActions-root button:last-child:active {
  color: #fff;
  background-color: #954120;
  border-color: #78341a;
  box-shadow: 1px 4px 3px rgba(0, 0, 0, 0.3);
}

.MuiFilledInput-underline:before,
.MuiFilledInput-underline:after,
.MuiFilledInput-underline:hover:before {
  border-bottom: 0 !important;
}

/* Alert */
.alert {
  margin-bottom: 10px;
}

.alert .MuiAlert-icon {
  margin: auto;
  margin-right: 10px;
}

.alert .MuiAlert-message {
  padding: 10px 0;
  word-break: break-all;
  width: auto;
}

/* Progress loading */
.overlay-progress {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-transition: -webkit-opacity 0.2s;
  transition: opacity 0.2s;
  opacity: 0.3;
  background-color: black;
  z-index: 999;
}

.progress-loading {
  position: absolute;
  z-index: 200;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
}

.progress-loading .progress-image {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  background-image: url('../images/spinner.gif');
  background-size: cover;
}

/* Pagination */
.empty-search-result {
  padding: 15px;
  text-align: center;
}

.wrapper-pagination {
  position: relative;
  margin: 10px;
}

.wrapper-pagination .pagination {
  width: auto;
  display: inline-block;
}

.wrapper-pagination .pagination li {
  margin-right: 2px;
}

.wrapper-pagination .page-info {
  padding-left: 5px;
}

.wrapper-pagination .pagination-right {
  float: right;
}

.wrapper-pagination select {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
}

.wrapper-pagination .MuiInput-underline:before,
.wrapper-pagination .MuiInput-underline:focus,
.wrapper-pagination .MuiInput-underline:hover,
.wrapper-pagination .MuiInput-underline:focus:not(.Mui-disabled):before,
.wrapper-pagination .MuiInput-underline:hover:not(.Mui-disabled):before,
.wrapper-pagination .MuiInput-underline:after,
.wrapper-pagination .MuiInput-underline:focus:not(.Mui-disabled):after,
.wrapper-pagination .MuiInput-underline:hover:not(.Mui-disabled):after {
  border-bottom: 0;
}

.wrapper-pagination .pagination-right > span {
  position: relative;
  top: -4px;
}

.wrapper-pagination .pagination-right .form-padding {
  padding-bottom: 1px;
}

/* Autofill background color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-background-clip: text !important;
}

.MuiFilledInput-root,
.MuiFilledInput-root:hover,
.MuiFilledInput-root:focus {
  border-radius: 4px;
  background-color: #fff !important;
}

.MuiFilledInput-adornedEnd,
.MuiFilledInput-adornedEnd:focus,
.MuiFilledInput-adornedEnd:hover {
  background-color: inherit !important;
}
.fullWidth,
.fullWidth span {
  max-width: 800px !important;
}
.fullWidth textarea {
  min-width: 500px;
}
