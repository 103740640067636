.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 0;
  border: 1px solid transparent;
}

.navbar:before,
.navbar:after {
  display: table;
  content: ' ';
}

.navbar:after {
  clear: both;
}

.whiteAppendix {
  color: #ffffff !important;
}

.whiteAppendix svg {
  color: #fff !important;
}

.navbar-header:before,
.navbar-header:after {
  display: table;
  content: ' ';
}

.navbar-header:after {
  clear: both;
}

.navbar-fixed-top .navbar-collapse {
  padding-right: 0;
  padding-left: 0;
}

.navbar-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

.navbar-fixed-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
  max-height: 340px;
}

.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}

.navbar-brand {
  float: left;
  height: 50px;
  font-size: 18px;
  line-height: 22px;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-brand > img {
  display: block;
}

.navbar-nav {
  margin: 7px -15px;
}

.navbar.navbar-inverse {
  border: none;
  box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.4);
}

.container-fluid {
  background-color: #313131;
  position: fixed;
  display: block;
  width: 100%;
  height: 50px;
}

.header-label-wrapper {
  position: absolute;
  top: 5px;
  left: 15px;
  color: #fff;
}

.header-label-wrapper p:first-child {
  font-size: 15px;
}

.header-label-wrapper p:last-child {
  font-size: 13px;
  margin-top: -3px;
}

.navbar-right .account-info {
  display: inline-block;
  padding-right: 15px;
  color: #fff;
  text-align: right;
  vertical-align: middle;
  margin-top: 5px;
  font-size: 13px;
}

.navbar-right .account-info a {
  text-decoration: underline;
  color: #fff;
}

.navbar-right .account-info a div {
  margin-bottom: 5px;
}

.navbar-right .btn-info {
  margin-right: 30px;
}

.navbar-right .btn-info:focus {
  color: #fff;
  background-color: #2579a9;
  border-color: #133d55;
}

.navbar-right .btn-info:hover {
  color: #fff;
  background-color: #2579a9;
  border-color: #1f648b;
}

.navbar-left {
  float: left !important;
}

.navbar-right {
  float: right !important;
  margin-right: -15px;
}

/* Sidebar */
.sidebar {
  width: 255px;
  background-color: #ededed;
  position: fixed;
  z-index: 900;
  transition: all 0.4s ease 0s;
  overflow-y: auto;
}

.sidebar.sidebar-active {
  height: calc(100% - 50px);
  overflow-y: scroll;
}

.sidebar.sidebar-not-active {
  margin-left: -255px;
  left: 25px;
  border-radius: 18px;
}

.sidebar.sidebar-not-active a {
  border-radius: 18px;
}

.sidebar.sidebar-not-active .sidebar-body {
  display: none;
}

.sidebar svg {
  fill: #000;
}

.sidebar a:hover svg,
.sidebar a.active svg {
  fill: #fff;
}

.sidebar a {
  color: #000;
  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
  border-bottom: 0.5px solid #ddd;
}

.sidebar a.active {
  color: #fff;
  background-color: #313131;
}

.sidebar .MuiListItem-root:hover,
.sidebar .MuiListItem-root:focus,
.sidebar a:hover,
.sidebar a:focus {
  color: #fff;
  background-color: #676666;
}

.sidebar .MuiMenuItem-root {
  min-height: auto !important;
}

.sidebar-header {
  height: 30px;
  margin-bottom: 0px;
}

.sidebar-header a {
  display: block;
  height: 100%;
  text-align: right;
  padding-right: 2px;
  background-color: #313131; /* For browsers that do not support gradients */
  background-image: linear-gradient(#313131, #6d6d6d);
}

.sidebar-header a svg {
  width: 25px;
  height: 100%;
  fill: #fff;
}

.sidebar .MuiListItem-root:hover svg,
.sidebar .MuiListItem-root:focus svg {
  fill: #fff;
}

.sidebar-body .MuiListItem-gutters {
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 0.5px solid #ddd;
}

.sidebar-body .MuiListItem-root {
  padding-top: 6px;
  padding-bottom: 6px;
}

.sidebar-body .MuiListItemIcon-root {
  min-width: 30px;
}

.MuiListItemText-root {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.sub-menu .MuiListItem-gutters {
  padding-left: 20px;
  padding-right: 20px;
}

.MenuText {
  width: 80%;
}
/* Breadcrumbs */
.breadcrumbs {
  background-color: #313131;
  border-radius: 0;
  transition: all 0.4s ease 0s;
  height: 30px;
}

.breadcrumbs ol {
  margin-left: 15px;
  height: 100%;
}

.breadcrumbs ol li p {
  color: #fff;
  vertical-align: middle;
}

.breadcrumbs ol li a {
  text-decoration: underline;
  color: rgba(255, 255, 255, 0.7);
}

.MuiBreadcrumbs-separator {
  color: #fff;
}

.container.sidebar-not-active .breadcrumbs ol {
  margin-left: 45px;
}

/* Content Area */
.app-frame {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 50px;
}

.wrapper-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0;
}

div[class*='RaLayout-content'] {
  padding: 0 !important;
}

.container {
  margin: 0 0 0 255px;
  transition: all 0.4s ease 0s;
  position: relative;
}

.container.sidebar-not-active {
  margin-left: 0;
}

/* Select */
.wrapper-select {
  position: absolute;
  right: 15px;
  margin-top: -28px;
}

.wrapper-select select {
  color: #fff;
  text-align-last: right;
}

.wrapper-select .MuiNativeSelect-icon {
  color: #fff;
  top: calc(50% - 10px);
}

.wrapper-select .MuiNativeSelect-select.MuiNativeSelect-select {
  padding-right: 20px;
}

.wrapper-select .MuiInput-underline:before,
.wrapper-select .MuiInput-underline:focus,
.wrapper-select .MuiInput-underline:hover,
.wrapper-select .MuiInput-underline:focus:not(.Mui-disabled):before,
.wrapper-select .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 0;
}

.wrapper-select div.global-shop {
  padding-left: 15px !important;
}

/* Table */
.table-responsive table th {
  background-color: #555 !important;
  color: #fff !important;
  white-space: nowrap;
  font-weight: bold !important;
}

.table-responsive .sectionExpandWrap table th {
  background-color: #777 !important;
}

.table-responsive {
  overflow-x: initial;
  min-height: 0.01%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive table tr td {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.table-responsive .MuiToolbar-root {
  padding-left: 0 !important;
}

.table-responsive .column-undefined {
  width: 30px;
  padding-left: 0 !important;
  padding-right: 5px !important;
}

.table-responsive .column-undefined a,
.table-responsive .column-undefined button {
  padding: 5px;
}

.table-responsive .column-undefined a svg,
.table-responsive .column-undefined button svg {
  font-size: 20px;
}

.table-responsive .MuiTableCell-sizeSmall {
  padding: 5px 10px;
}

.table-responsive .MuiTableCell-head:last-child {
  padding-right: 5px;
  padding-left: 8px;
}

.table-responsive td.operation {
  padding-right: 5px;
  padding-left: 0;
  width: 135px;
  white-space: nowrap !important;
}

.table-responsive td.edit-only {
  width: 35px;
}

.table-responsive td span {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.table-responsive span.wrapper-tag {
  line-height: normal;
}

.list-page {
  padding: 1px 15px 15px 15px;
}

.list-page .MuiPaper-root {
  position: initial;
}

/* Input Form*/
.input-form-table {
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 2px 2px !important;
}

.input-form-table .table-item-name {
  width: 250px;
  text-align: right;
  padding: 5px;
  /* background-color: #1bc2d2; */
}

.input-form-table tr {
  height: 30px;
  background-color: whitesmoke;
}

.input-form-table tr:nth-child(2n) {
  height: 30px;
  background-color: rgb(220, 220, 220);
}

.input-form-table td:nth-child(2) {
  padding-left: 10px;
  padding-right: 10px;
}

.input-form-table .custom-select {
  width: auto !important;
}

.input-form-table .MuiSelect-selectMenu {
  border-radius: 4px;
  height: auto;
  padding: 12px;
  background-color: #fff;
}

.input-form-table .MuiSelect-selectMenu:focus,
.input-form-table .MuiSelect-selectMenu:hover {
  background-color: #fff;
  border-radius: 4px;
}

.input-form-table .MuiFormControl-marginDense {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
}

.input-form-table .MuiFormControl-marginDense label {
  display: none;
}

.input-form-table .MuiFormHelperText-contained {
  display: block;
  margin-left: 3px;
  margin-right: 3px;
}

.input-form-table .no-help-block .MuiFormHelperText-contained {
  display: none;
}

.input-form-table .no-help-block .MuiFormHelperText-contained.Mui-error {
  display: block !important;
}

.input-form-table .MuiTableCell-root {
  padding: 10px;
}

.input-form-table .MuiSwitch-colorPrimary.Mui-checked {
  color: #38a4d7;
}

.input-form-table .MuiFormGroup-root {
  flex-direction: inherit;
}

.input-form-table .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #7ab3d4;
}

.input-form-table span.required-asterisk {
  color: #f44336;
  font-size: 18px;
  display: initial;
}

span.tag {
  background-color: #e6e6e6;
  padding: 7px;
  border-radius: 5px;
  margin-right: 5px;
  vertical-align: middle;
}

.input-form-table
  .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
  margin-top: 0;
}

.input-form-table .previews {
  margin-top: 5px;
}

.input-form-table input:-webkit-autofill,
.input-form-table input:-webkit-autofill:hover,
.input-form-table input:-webkit-autofill:focus {
  -webkit-text-fill-color: #555;
}

.wrapper-filter {
  margin-bottom: 15px;
}

.create-page,
.edit-page {
  position: relative;
  margin: 1px 15px 15px 15px;
}

/* Toolbar */
.list-page .MuiAccordionActions-root {
  justify-content: left;
}

.create-page .MuiToolbar-root,
.edit-page .MuiToolbar-root {
  padding-left: 18px !important;
  padding-right: 18px !important;
  position: initial;
  margin-top: 16px;
  justify-content: space-between;
}

.create-page > div:nth-child(1),
.edit-page > div:nth-child(1) {
  margin-top: 0;
}

.create-page form > div:nth-child(3),
.edit-page form > div:nth-child(3) {
  height: 0;
}

.create-page .MuiCard-root,
.edit-page .MuiCard-root {
  margin-top: 1em;
}

.create-page .MuiCardContent-root {
  padding: 16px 16px 0 16px;
}

.create-page .MuiToolbar-root {
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 16px;
  background-color: #f5f5f5;
}

.create-page .MuiToolbar-regular {
  min-height: 56px;
}

/* Barel table */
.barrel-table {
  width: 100%;
}

.barrel-table .MuiTableCell-root {
  padding: 5px 16px;
}

.barrel-table .beer-animation svg {
  width: 40px;
  height: 40px;
}

.barrel-table .beer-animation span.beerInfo {
  position: absolute;
  padding-left: 5px;
  padding-top: 0.3em;
}

.input-form-dialogue {
  margin-top: 16px;
}

.short-column {
  width: 90px;
}

.hidden {
  display: none !important;
}

/* Error Page */
.error-page {
  padding-top: 75px;
  text-align: center;
}

/* Edit Profile Page */
.wrapper-edit-profile {
  display: flex;
}

.wrapper-edit-profile .edit-profile {
  flex: 1 1 auto;
  overflow: hidden;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
}

/* Shop Page */
.shopActionHeader {
  margin-top: 1rem !important;
  padding: 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  margin-top: 1rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

/* Operation Log Page */
.fullContent {
  overflow: auto !important;
  max-width: 100% !important;
  display: block !important;
  white-space: break-spaces !important;
}

/* TapBeer Page */
.country-flag {
  max-width: 15px;
  margin-right: 0.75rem;
  float: left;
}

.close-dialog-icon {
  position: absolute !important;
  top: 0.3em;
  right: 0px;
}

.close-detail-dialog {
  display: block !important;
  margin: 0 auto !important;
}

.min-width-500 {
  min-width: 500px;
}

.kegRemainingPercentage {
  position: relative;
  top: -1.15em;
  left: -2.5em;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
}

.topRightTitle {
  width: 70px;
  float: right;
}

.beerLogo {
  max-width: 100px;
  max-height: 100px;
}

.grayText {
  color: #888;
}

.countryFlag {
  max-width: 30px;
}

.countryName {
  position: relative;
  top: -10px;
  padding-left: 0.3em;
}

.color-option::before {
  content: '';
  display: inline-block;
  margin-right: 1rem;
  width: 10px;
  height: 10px;
  border: solid 0.5px #ddd;
}

.color-option.red::before {
  background-color: #f44336;
}

.color-option.yellow::before {
  background-color: yellow;
}

.color-option.white::before {
  background-color: white;
}

.color-option.black::before {
  background-color: black;
}

.color-option.orange::before {
  background-color: orange;
}
.color-option.sour::before {
  background-color: #f4f5ed;
}
.color-option.highball::before {
  background-color: #f9f7dc;
}
/* Chart Page */

.previousDayButton::before,
.nextDayButton::after {
  display: block;
  font-family: 'Font Awesome 5 Pro';
  color: #000;
}

.previousDayButton::before {
  content: '\f053';
}

.previousDayButton,
.nextDayButton {
  color: #000 !important;
}

.nextDayButton::after {
  content: '\f054';
}

.chartDatepicker input {
  max-width: 50px;
  text-align: center;
}

.borderTableHead > tr > th,
.borderTableBody > tr > td {
  border: 0.5px solid #dddddd;
}

.borderTableHead > tr > th {
  color: #ffffff;
  background-color: #555555;
}

.borderTableBody > tr:nth-child(even) {
  background-color: #fafafa;
}

.borderTableBody > tr:hover {
  background-color: #aaaaaa;
}

.borderTableBody > tr:hover > td {
  color: #ffffff !important;
}

.chartDatepicker > div::after,
.chartDatepicker > div::before {
  display: none !important;
}

/* Sale History */

.startDateInput label {
  padding-left: 1.3rem;
}

/* Sale Report */
.dataTableFooter {
  background-color: #555555 !important;
}

.dataTableFooter > td,
.optionShop input,
.optionShop .MuiAutocomplete-endAdornment svg {
  color: #ffffff !important;
}

/* Realtime Indicator Blinking Animation */
.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
}

/* Section */
.sectionExpandWrap {
  padding-left: 4rem;
}

.sectionExpandWrap table {
  border: 0.5px solid #ddd;
}

.sectionExpandWrap th {
  padding: 5px !important;
  width: 10%;
}

.sectionExpandWrap th:last-child,
.sectionExpandWrap th:nth-last-child(2) {
  width: 3% !important;
}

.TableHeading {
  /* color: #2579a9 !important; */
  white-space: nowrap;
  font-weight: bold !important;
}

/* Common */
.green {
  color: green;
}

.red {
  color: #f44336;
}

.inline {
  display: inline !important;
}

.inactiveBlackButton {
  background-color: #555;
  color: #fff;
}

.activeButtonStyle {
  background-color: #555 !important;
  color: #fff !important;
}

.Mui-error > input,
.Mui-error > textarea {
  border-color: #f44336 !important;
}

.Mui-error > .MuiSelect-select {
  border: 1px solid #f44336 !important;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@media (min-width: 600px) {
  .create-page .MuiToolbar-regular {
    min-height: 64px;
  }
}

@media screen and (max-width: 991px) {
  .table-responsive table thead tr th,
  .table-responsive table thead tr td,
  .table-responsive table tbody tr th,
  .table-responsive table tbody tr td,
  .table-responsive table tfoot tr th,
  .table-responsive table tfoot tr td {
    white-space: nowrap;
  }
}

@media (min-width: 768px) {
  .navbar {
    border-radius: 4px;
  }

  .navbar-fixed-top {
    border-radius: 0;
  }

  .navbar .container .navbar-brand,
  .navbar .container-fluid .navbar-brand {
    margin-left: -15px;
  }

  .navbar-nav {
    float: left;
    margin: 0;
  }
}

@media (min-width: 992px) {
  .table-responsive table thead tr th,
  .table-responsive table thead tr td,
  .table-responsive table tbody tr th,
  .table-responsive table tbody tr td {
    word-wrap: break-word;
  }
}

@media (max-device-width: 480px) and (orientation: landscape) {
  .navbar-fixed-top .navbar-collapse {
    max-height: 200px;
  }
}

#logo-shallwetap {
  height: 40px;
}

#logo-shallwetap-login {
  height: 200px;
}
